import React from 'react'
import {
  CloseButton,
  DivisionLine,
  ModalOverlay,
  ModalWindow,
  OrDivisionSection,
  PrimaryButton,
  SubTextSection
} from './UserEventLevelLimitationModal.styles'
import LevelLimitationImage from '../../assets/userEventLevelLimitation.svg'
import Link from 'next/link'
import crossIcon from '../../assets/cross.svg'
import { useDispatch } from 'react-redux'
import { setShowMembershipModal } from '../../redux/designerReducers/designerReducer'

export const UserEventLevelLimitationModal = ({ onClose }) => {
  const dispatch = useDispatch()

  return (
    <ModalOverlay>
      <ModalWindow>
        <CloseButton onClick={onClose}>
          <img src={crossIcon} alt="" />
        </CloseButton>
        <img src={LevelLimitationImage} alt="" />
        <p>
          You currently <strong>Level 1</strong>.
          <br />
          Unlock all events at <strong>Level 3+</strong> or become a{' '}
          <strong>Member</strong>.
        </p>
        <PrimaryButton
          onClick={() => {
            onClose()
            dispatch(setShowMembershipModal(true))
          }}
        >
          Become a Member
        </PrimaryButton>
        <OrDivisionSection>
          <DivisionLine />
          <span>Or</span>
          <DivisionLine />
        </OrDivisionSection>
        <SubTextSection>
          Learn How to <Link href="/help-center/rewards/exp">Level Up</Link>?
        </SubTextSection>
      </ModalWindow>
    </ModalOverlay>
  )
}
