import moment from 'moment'

export const getMyEventCountdown = event => {
  const isEnded =
    event?.expiresAt?.iso && moment(event?.expiresAt?.iso).isBefore(moment())

  if (isEnded) {
    const in24h = moment(event?.expiresAt?.iso).add(24, 'hours')

    if (in24h.isBefore(moment())) return 'Closed'

    return Math.abs(moment().diff(in24h, 'hours')) + ' hours'
  }

  if (isEnded) return 'Closed'

  return moment(event?.featuredAt?.iso).isBefore(moment())
    ? moment(event?.expiresAt?.iso).fromNow(true)
    : 'Pending'
}
