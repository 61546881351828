import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalWindow = styled.div`
  width: 700px;
  height: 680px;
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;

  & p {
    margin-top: 48px;
    text-aling: center;
  }
`

export const PrimaryButton = styled.button`
  background: #ff5e6d;
  border-radius: 30px;
  width: 280px;
  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background: #ff4a5b;
  }
`

export const OrDivisionSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  & span {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
    line-height: 24px;
    color: #9b9b9b;
  }
`

export const DivisionLine = styled.div`
  display: block;
  width: 124px;
  height: 1px;
  background: #d8d8d8;
`

export const SubTextSection = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin-top: 20px;

  & a {
    font-weight: 700;
    text-decoration: underline;
  }
`

export const CloseButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  top: 25px;
  left: 25px;
  filter: invert(1);
`
